// @import '~antd/dist/antd.css';
@import '~antd/dist/antd.dark.css'; // Introduce the official dark less style entry file
.App {
  height: 100vh;
  width: 100vw;
  overflow: auto;
}

.text-center {
  text-align: center;
}

.mt-1em {
  margin-top: 1em;
}

.mt-2em {
  margin-top: 2em;
}

.mb-0_5em {
  margin-bottom: 0.5em;
}

.mb-0_5em-imp {
  margin-bottom: 0.5em!important;
}

.spinner-container {
  padding: 2em;
  text-align: center;
}
