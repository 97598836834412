.main-wl-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;

  .main-wl-form {
    max-width: 90vw;
    width: 570px;
    min-height: 40vh;
    padding: 20px;
  }
}

.submission-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
}
