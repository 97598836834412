.main-layout-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  min-height: 100vh;
  padding-bottom: 100px;
  background: #000000db;
  .main-intro-container {
    width: 100vw;
    border-radius: 20px;
    padding: 20px;
    margin: auto;
  }
  .main-layout-form {
    background: #ffffffdf;
    width: 570px;
    min-height: 40vh;
    border-radius: 20px;
    padding: 20px;
    margin-inline: auto;
  }
}

.main-collabers-containers {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  .collab-x {
    color: white;
    font-size: 2rem;
    margin-inline: 20px;
  }

  .tru {
    filter: invert(100%);
    max-height: 80px;
    object-fit: contain;
    max-width: 36vw;
  }

  .anybodies {
    max-height: 80px;
    object-fit: contain;
    max-width: 36vw;
  }
}

.collab-title {
  margin: auto;
  text-align: center;
  color: white;
  font-size: 41px;
  font-weight: 200;
  margin-top: 74px;
  letter-spacing: 3px;
}

.collab-desc {
  text-align: center;
  color: white;
  max-width: 90vw;
  margin: auto;
  margin-top: 30px;
  font-size: 19px;
  font-weight: 100;
  letter-spacing: 1px;
  margin-bottom: 40px;
  width: 80%;
}

.collab-requirement {
  color: white;
  display: flex;
  margin: auto;
  text-align: center;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
  font-weight: 100;
  font-size: 18px;
}
